<template>
  <div class="container">
    <div class=" bg-dark rounded-2 pt-3 ps-3 ">
      <div class="row g-3">
        <div class="col-lg-12 mt-3">
          <div class="mb-3 form-check">
            <input
              type="checkbox"
              class="form-check-input"
              id="exampleCheck1"
            />
            <label class="form-check-label  text-light " for="exampleCheck1 "
              >+Add optinal mailing address</label
            >
          </div>
        </div>
      </div>

      
    </div>
    <button class="btn btn-primary mt-3" type="submit">Submit</button>
  </div>

</template>

<script setup></script>

<style scoped></style>
